/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';

// Strap

// Router
// Table
import 'toastr/build/toastr.min.css';
// Breadcrumb

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';

import {APPLICATION_ID, NAVBAR, PERMISION} from 'Apps'

import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
// import BusinessPoint from "../../../Component/businessPoint"
import Planogram from './planogram';
import PlanogramFranke from './planogram_franke';
import PlanogramMilano from './planogram_milano';
import PlanogramRetail from './planogram_retail';
import PlanogramRetailOld from './planogram_retail_old';
import PlanogramLeVending from './planogram_leVending';
import PlanogramMCProc from './planogram_mcproc';
import PlanogramMCProcV2 from './planogram_mcprocV2';
import PlanogramMCProcV3 from './planogram_mcprocV3';
import PlanogramRetailHpp from './planogram_retail_hpp';
import PlanogramWater from "./planogram_water";
import PlanogramXy from './planogram_retail_xy';
import {
  readInfo,
} from '../../store/business-point/actions';
import 'react-loading-skeleton/dist/skeleton.css';
import Pages403 from '../pages-403';
const navbar = NAVBAR
const permision = PERMISION
class BodyPlanogram extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const deviceId = safeDeepGet(this.props.match.params, 'id');
    const deviceType = safeDeepGet(this.props.match.params, 'type');
    this.state = { deviceId, deviceType };
  }
  componentDidMount() {
    console.log();
    // this.props.ReadBusinessPointInfo(this.state.deviceId);
  }
  render() {
    // TODO Loading
    // const type = safeDeepGet(this.props, [
    //   'readInfoResult',
    //   'body',
    //   'info',
    //   'type',
    // ]);
    const { deviceType, deviceId } = this.state;
    const history = this.props.history;
    const dataProps = {
      deviceId: deviceId,
      history: history,
    };
    return (
      <>
        {!permision ||
        safeDeepGet(navbar, ['Business Point', 'edit'], 'off') == 'off' ? (
          <Pages403 />
        ) : deviceType == 'franke'|| deviceType == 'ipilot' || APPLICATION_ID == '1000000226'? (
          <PlanogramFranke data={deviceId}  type={deviceType} />
        ) : deviceType == 'levending' ? (
          <PlanogramLeVending data={deviceId}  type={deviceType} />
        ) : deviceType == 'water_dispenser' ? (
          <PlanogramWater data={deviceId}  type={deviceType} />
        ) :  deviceType == 'reyeah' || deviceType == 'bluemart_2106_new' ||
          deviceType == 'bluemart_2105' ? (
          <PlanogramRetail data={dataProps} type={deviceType} />
        ) : deviceType == 'bluemart_2106_hpp' ? (
          <PlanogramRetailHpp data={dataProps} type={deviceType} />
        ) : deviceType == 'milano' ? (
          <PlanogramMilano data={deviceId}  type={deviceType}/>
        ) : deviceType == 'xy' ? (
          <PlanogramXy data={dataProps}  type={deviceType}/>
        ) : deviceType == 'xy_multi' || deviceType  == 'gea' ? (
          <PlanogramRetailOld data={dataProps}  type={deviceType}/>
        ) :
          deviceType == 'bluemart_2106' ||
          deviceType == 'bluemart_5508' ? (
          <PlanogramRetailOld data={dataProps} type={deviceType} />
        ) : deviceType == 'mcpro' ? (
          <PlanogramMCProc data={deviceId} type={deviceType} />
        ) : deviceType == 'mcprov2' ? (
          <PlanogramMCProcV2 data={deviceId} type={deviceType} />
        ) : deviceType == 'mcprov3' ? (
          <PlanogramMCProcV3 data={deviceId} type={deviceType} />
        ) : APPLICATION_ID == '1000000159' ? (
          <PlanogramMilano data={deviceId}  type={deviceType}/>
        ) : APPLICATION_ID == '1000000211' ? (
          <PlanogramFranke data={deviceId}  type={deviceType} />
        ) : (
          <Planogram data={dataProps}  type={deviceType} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  readInfoResult: safeDeepGet(state, ['businessPoint', 'readInfo'], {}),
});

const mapDispatchToProps = dispatch => ({
  ReadBusinessPointInfo: id => dispatch(readInfo(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BodyPlanogram);
